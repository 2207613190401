import {useEffect,  Suspense, lazy } from "react";
import { initializeTagManager } from "./gtm";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
import ADEProductsItem from "./components/Products/ADEProductsItem";
const HomeOne = lazy(() => import("./pages/HomeOne"));
// const About = lazy(() => import("./pages/About"));
const MarketApplication = lazy(() => import("./pages/MarketApplication"));
const Transportation = lazy(() => import("./pages/Transportation"));
const Grid = lazy(() => import("./pages/Grid"));
const Industrial = lazy(() => import("./pages/Industrial"));
const BlogGrid = lazy(() => import("./pages/BlogGrid"));
const BlogDetails = lazy(() => import("./pages/BlogDetails"));
const Contact = lazy(() => import("./pages/Contact"));
const MillContact = lazy(() => import("./pages/MillContact"));
const Career = lazy(() => import("./pages/Career"));

const Products = lazy(() => import("./pages/Products"));
const ProductDetails = lazy(() => import("./pages/ProductDetails"));
// const ActivatedDryElectrode = lazy(() => import("./pages/ActivatedDryElectrode"));
const Technology = lazy(() => import("./pages/Technology"));
const ADECap = lazy(() => import("./pages/ADECap"));
const ADEProductDetails = lazy(() => import("./pages/ADEProductDetails"));
const LithiumIon = lazy(() => import("./pages/LithiumIon"));
const CustomUltraCapModules = lazy(() => import("./pages/CustomUltraCapModules"));
const UltraCells = lazy(() => import("./pages/UltraCells"));
const Mills = lazy(() => import("./pages/Mills"));
const MillProductDetails = lazy(() => import("./pages/MillProductDetails"));
const WindRetrofit = lazy(() => import("./pages/WindRetrofit"));
const ValueProposition = lazy(() => import('./pages/ValueProposition'));

const WhitePapers = lazy(() => import("./pages/WhitePapers"));
const WhereToBuy = lazy(() => import("./pages/WhereToBuy"));

function App() {

  useEffect(() => {
    initializeTagManager();
  }, []);

  useEffect(() => {
    AOS.init({
        offset: 80,
        duration: 1000,
        once: true,
        easing: 'ease',
    });
    AOS.refresh();
    
  }, [])

  return (
      <Router>
        <NavScrollTop>
          <Suspense fallback={<div />}>
                <Routes>
                  <Route path={`${process.env.PUBLIC_URL + "/"}`} element={<HomeOne/>}/>
                  {/* <Route path={`${process.env.PUBLIC_URL + "/about"}`} element={<About/>} /> */}

                  <Route path={`${process.env.PUBLIC_URL + "/ucap-applications"}`}>
                    <Route index element={<MarketApplication/>} />
                    <Route path={`transportation`} element={<Transportation/>} />
                    <Route path={`grid`} element={<Grid />} />
                    <Route path={`industrial`} element={<Industrial/>} />
                    <Route path={`wind-retrofit`} element={<WindRetrofit/>} />
                    <Route path={`wind-retrofit/value-proposition`} element={<ValueProposition/>} />
                  </Route>

                  <Route path={`${process.env.PUBLIC_URL + "/white-papers"}`} element={<WhitePapers/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/news"}`} element={<BlogGrid/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/news-details/:id"}`}element={<BlogDetails/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/contact"}`} element={<Contact/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/calendering-and-lamination-equipment-contact"}`} element={<MillContact/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/career"}`} element={<Career/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/where-to-buy"}`} element={<WhereToBuy/>} />

                  <Route path={`${process.env.PUBLIC_URL + "/products"}`} element={<Products/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/products/:name"}`} element={<ProductDetails/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/activated-dry-electrode"}`}>
                    <Route index element={<Technology/>} />
                    {/* <Route path={`technology`} element={<Technology/>} /> */}
                    <Route path={`ultra-and-lithium-ion-capacitors`} element={<ADECap/>} />
                    <Route path={`ultra-and-lithium-ion-capacitors/:name`} element={<ADEProductDetails/>} />
                  </Route>                  
                  <Route path={`${process.env.PUBLIC_URL + "/lithium-ion-capacitor-cells"}`} element={<LithiumIon/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/ultracapacitor-modules"}`} element={<CustomUltraCapModules/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/ultracapacitor-cells"}`} element={<UltraCells/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/calendering-and-lamination-equipment"}`} element={<Mills/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/calendering-and-lamination-equipment/:name"}`} element={<MillProductDetails/>} />


                  <Route path={`${process.env.PUBLIC_URL + "/wind-retrofit"}`}>
                    <Route index element={<WindRetrofit/>} />
                    <Route path={`value-proposition`} element={<ValueProposition/>} />
                  </Route>

                  {/* error page? */}
                  {/* <Route path="*" /> */}
                </Routes>
            </Suspense>
        </NavScrollTop>
      </Router>
  );
}

export default App;
