import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";

const ADEProductsItem = ({ data }) => {
    return (
        <div className="blog">
            <div className="thumbnail">
                <Link to={process.env.PUBLIC_URL + `/activated-dry-electrode/ultra-and-lithium-ion-capacitors/${data.name}`} className="image"><img src={process.env.PUBLIC_URL + data.image} style={{}} alt="Blog Image" /></Link>
            </div>
            <div className="info" style={{display:'grid', justifyContent:'center'}}>
                {/* <ul className="meta">
                    <li><i className="far fa-calendar"></i>{data.date}</li>
                    <li><i className="far fa-eye"></i>{data.view}</li>
                </ul> */}
                <h3 className="title" style={{textAlign:'center'}}><Link to={process.env.PUBLIC_URL + `/activated-dry-electrode/ultra-and-lithium-ion-capacitors/${data.name}`}>{data.title}</Link></h3>
                <Link to={process.env.PUBLIC_URL + `/activated-dry-electrode/ultra-and-lithium-ion-capacitors/${data.name}`} className="link" style={{display:'flex', justifyContent:'center'}}> <mark>View Product</mark> </Link>
            </div>
        </div>
    )
}

ADEProductsItem.propTypes = {
    data: PropTypes.object
};

export default ADEProductsItem
